import React from 'react';
import { Helmet } from 'react-helmet';
import '../../styles/template.css';
import '../../styles/about.css';
import '../../styles/team.css';
import Layout from '../../components/Layout';

const Page = ({ location }) => {
  return (
    <Layout location={location}>
      <Helmet>
        <title>Racqy - Cookies policy</title>
      </Helmet>
      <div>
        <main id="cookies-policy">
          <h1
            style={{
              textAlign: 'center',
              marginTop: '100px',
              fontSize: '35px',
              marginBottom: '20px',
              fontWeight: 'bold',
            }}
          >
            Cookies policy
          </h1>
          <section className="content-section">
            <div
              className="page-content"
              style={{ paddingTop: 30, flexDirection: 'column' }}
            >
              <h2>Cookies policy</h2>
              <p>
                Cookies are essential to ensure that you can use Racqy and its
                features on a recurring basis. Racqy uses cookies when you visit
                the site. A cookie is a small information capsule that is placed
                in the user’s computer. The cookie cannot identify you
                personally, only the web browser you are using to visit the
                site. The cookie does not contain any viruses and cannot alter
                or remove any other information stored on your computer.
              </p>
              <br />
              <p>
                Racqy uses so-called session cookies. These contain an ID that
                enables Racqy’s servers to distinguish your web browser from
                those of other visitors. A session cookie is saved for as long
                as you are visiting Racqy. As soon as you leave the site or
                close your web browser your session is deleted and so is your
                session cookie.
              </p>
              <br />
              <p>
                For you as a user to be logged in automatically, we use
                auto-login cookies. Racqy also collects statistics on visits to
                the site using cookies. The information collected includes data
                concerning which pages on Racqy have been visited and how long
                time the user has spent on the site. These statistics do not
                contain any form of personal information.
              </p>
              <br />
              <p>
                You have the right to deny Racqy permission to leave cookies on
                your computer, although in this case, you can no longer use
                Racqy for functional reasons. You can also choose which level of
                cookies you wish to allow on your web browser.
              </p>
            </div>
          </section>
        </main>
      </div>
    </Layout>
  );
};

export default Page;
